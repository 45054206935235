import React, {useEffect, useRef, useState} from 'react';
import {useAppSelector, useAppDispatch} from '../../utils/hooks';
import {useHistory} from 'react-router-dom';
import agliveLogoLarge from '../../img/agliveLogoLarge.png';
import {
  ContainerFoot,
  Line,
  Logo,
  SsoContainer,
  SsoLeftSection,
  SsoLoginBox,
  SsoRightSection,
} from './styles';
import {Header2, Header3} from '../../presentation/word';
import './styles.css';
import COLOR from '../../styled/colors';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import MyButton from '../../presentation/button';
import {
  Grid,
  TextField,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core';
import {Buttons} from '../../presentation/ButtonsGroup';
import CONSTANT from '../../config/constant';
import {clearWarning, isAllowedtoSubmit} from '../../utils/validation';
import {callAPI} from '../../utils/network';
import API from '../../config/api';
import {POST_BUSINESS_PROFILE_DATA} from '../../store/profile/types';
import {ClassNameMap} from '@material-ui/styles';
import InvitationCodeModal from './InvitationCodeModal';
import {updateSignupUserProfile} from '../../store/profile/actions';
import {submitInvitationCode} from '../../store/auth/actions';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  signupContainer: {
    marginTop: 30,
    marginBottom: -50,
  },
  textfield: {
    height: 50,
    marginTop: 6,
  },
  label1: {
    fontWeight: 700,
  },
  label2: {
    fontWeight: 700,
    marginTop: 12,
  },
  label3: {
    marginTop: 20,
  },
  invitationCode: {
    height: 50,
    marginTop: 6,
    width: 320,
  },
  greenText: {
    color: COLOR.GREENT_TEXT,
    fontSize: 16,
    textTransform: 'none',
  },
  grayText: {
    fontWeight: 400,
    marginTop: 16,
    color: COLOR.GRAY_TEXT,
    fontSize: 14,
    padding: '0px 20px 0px 20px',
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '-60px 95px 0px 45px',
    padding: 20,
  },
  header2: {
    marginTop: 30,
  },
  line1: {
    width: '80%',
  },
  line2: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  header3: {
    textAlign: 'left',
  },
  QuestionIcon: {
    width: '20px',
    height: '20px',
    padding: '0px 0px',
  },
}));

export type InvitationCodeRefObj = {
  getInvitationCode: () => any;
};

export type InvitationCodeModalProps = {
  classes: ClassNameMap<any>;
};

type Props = {};
type SignupDetailsType = {
  fullName: string;
  email: string;
  businessName: string;
  businessNumber: string;
};

const initailSignupDetails: SignupDetailsType = {
  fullName: '',
  email: '',
  businessName: '',
  businessNumber: '',
};

const SsoLogin: React.FC<Props> = (_) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userId = useAppSelector(
    (state) => state.user.userProfileData.externalIds.userId,
  );
  const [signupDetails, setSignupDetails] =
    useState<SignupDetailsType>(initailSignupDetails);
  const [signupValidation, setSignupValidation] = useState({
    ...initailSignupDetails,
  });
  const {name, emailAddress} = useAppSelector(
    (state) => state.user.userProfileData,
  );
  const [joinBusinessFlag, setJoinBusinessFlag] = useState(false);
  const {companyName, companyNumber} = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const invitationCodeRef = useRef<InvitationCodeRefObj>(null);

  const handleSignupChange =
    (key: keyof typeof signupDetails) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSignupDetails((prevState) => ({
        ...prevState,
        [key]: e.target.value,
      }));
    };

  const handleSignup = () => {
    const userInfo = {
      name: signupDetails.fullName,
      emailAddress: signupDetails.email,
      mobileNumber: '',
    };
    // update user name and email
    dispatch(updateSignupUserProfile(userInfo, userId));

    if (!joinBusinessFlag) {
      const validatedData = validateSignupData(signupDetails);
      setSignupValidation(validatedData);
      const isAllowed = isAllowedtoSubmit(validatedData);
      if (isAllowed) {
        history.push({
          pathname: '/select-industry',
          state: {
            businessName: signupDetails.businessName,
            businessNumber: signupDetails.businessNumber,
          },
        });
      }
    } else {
      history.push({
        pathname: '/private',
        state: {fromAuth: true},
      });
    }
  };

  const submitCodeHandler = (invitationCode: string, userId: string) => {
    return async (dispatch: (arg0: any) => void) => {
      try {
        if (!invitationCode.length || !userId) return;

        const businessId = await submitInvitationCode(
          invitationCode.trim(),
          userId,
        );

        const response = await callAPI({
          url: API.POST.getTokenbyExternalId,
          method: 'POST',
          data: {
            latestDetails: true,
            status: ['exist'],
            externalIds: [{agliveToken: businessId}],
            type: [],
          },
        });

        if (response) {
          const businessData = response[0]?.details;

          dispatch({
            type: POST_BUSINESS_PROFILE_DATA,
            payload: businessData,
          });

          setJoinBusinessFlag(true);
        }
      } catch (e) {
        console.error('error: ', e);
      }
    };
  };

  // reset validationState and warnings onKeyDown
  useEffect(() => {
    const resetValidationState = () =>
      setSignupValidation((prevState) => clearWarning(prevState));
    window.addEventListener('keydown', resetValidationState);

    return () => {
      window.removeEventListener('keydown', resetValidationState);
    };
  }, []);

  useEffect(() => {
    setSignupDetails((prevState) => ({
      ...prevState,
      fullName: name,
      email: emailAddress,
    }));
  }, [emailAddress, name]);

  return (
    <Grid container className={classes.outerContainer}>
      <SsoContainer>
        <SsoLoginBox>
          <SsoLeftSection>
            <div className={classes.leftSection}>
              <Logo src={agliveLogoLarge} />
              <Header2 invert={true} className={classes.header2}>
                Prove the value of your product and boost sales{' '}
              </Header2>
            </div>
            <ContainerFoot>
              Copyright &reg; 2020 Aglive International Pty Ltd ACN 605 120 081
            </ContainerFoot>
          </SsoLeftSection>
          <SsoRightSection>
            <Header2 invert={true} className={classes.header2}>
              Welcome to Aglive
            </Header2>
            <Line mt={25} className={classes.line1} />
            <Grid
              item
              container
              direction="column"
              xs={4}
              className={classes.signupContainer}>
              <Typography variant="h6" align="left" className={classes.label1}>
                {'Full Name*'}
              </Typography>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                value={signupDetails.fullName}
                onChange={handleSignupChange('fullName')}
                className={classes.textfield}
                helperText={signupValidation.fullName}
                error={!!signupValidation.fullName}
              />
              <Typography variant="h6" align="left" className={classes.label2}>
                {'Email*'}
              </Typography>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                value={signupDetails.email}
                onChange={handleSignupChange('email')}
                className={classes.textfield}
                helperText={signupValidation.email}
                error={!!signupValidation.email}
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={4}
              style={{
                marginTop: 30,
              }}>
              <Header3 invert={true} className={classes.header3}>
                Business Info
              </Header3>
              <hr className={classes.line2} />
              <Grid
                item
                container
                justifyContent="flex-end"
                alignContent="flex-start">
                {!joinBusinessFlag ? (
                  <Button
                    onClick={() => {
                      dispatch(
                        toggleModal({
                          status: 'warning',
                          title: 'Enter Invitation Code',
                          customContent: (
                            <InvitationCodeModal
                              ref={invitationCodeRef}
                              classes={classes}
                            />
                          ),
                          renderButton: (
                            <Buttons
                              leftButtonTitle="Cancel"
                              rightButtonTitle="Submit"
                              leftButtonOnClick={() => {
                                dispatch(toggleModalOff());
                              }}
                              rightButtonOnClick={() => {
                                const code =
                                  invitationCodeRef.current?.getInvitationCode();
                                dispatch(
                                  submitCodeHandler(code, userId),
                                );
                                dispatch(toggleModalOff());
                              }}
                            />
                          ),
                        }),
                      );
                    }}>
                    <span className={classes.greenText}>
                      <u>{'Join Existing Business?'}</u>
                    </span>
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setJoinBusinessFlag(false);
                      dispatch({
                        type: POST_BUSINESS_PROFILE_DATA,
                        payload: {},
                      });
                    }}>
                    <span className={classes.greenText}>
                      <u>{'Register New Business?'}</u>
                    </span>
                  </Button>
                )}
              </Grid>
              <Typography variant="h6" align="left" className={classes.label1}>
                {'Business Name*'}
              </Typography>
              {joinBusinessFlag ? (
                <Typography
                  variant="h6"
                  align="left"
                  className={classes.label3}>
                  {companyName}
                </Typography>
              ) : (
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={signupDetails.businessName}
                  onChange={handleSignupChange('businessName')}
                  className={classes.textfield}
                  helperText={signupValidation.businessName}
                  error={!!signupValidation.businessName}
                />
              )}

              <Typography variant="h6" align="left" className={classes.label2}>
                {'Business Number*'}
              </Typography>
              {joinBusinessFlag ? (
                <Typography
                  variant="h6"
                  align="left"
                  className={classes.label3}>
                  {companyNumber}
                </Typography>
              ) : (
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={signupDetails.businessNumber}
                  onChange={handleSignupChange('businessNumber')}
                  className={classes.textfield}
                  helperText={signupValidation.businessNumber}
                  error={!!signupValidation.businessNumber}
                />
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={4}
              alignContent="center"
              justifyContent="center"
              alignItems="center">
              <MyButton
                text={'Sign Up'}
                variant="contained"
                width={296}
                height={64}
                fontSize={25}
                marginTop={-20}
                onClick={handleSignup}
              />
              <Typography align="center" className={classes.grayText}>
                By clicking on Sign Up, you agree to Aglive&apos;s Terms of Use
                and Privacy Policy
              </Typography>
              <Button
                onClick={() => {
                  history.push({
                    pathname: '/qr-login',
                    state: {fromAuth: true},
                  });
                }}>
                <span className={classes.greenText}>
                  <u>{'Sign in using QR Scan'}</u>
                </span>
              </Button>
            </Grid>
          </SsoRightSection>
        </SsoLoginBox>
      </SsoContainer>
    </Grid>
  );
};

export default SsoLogin;

const validateSignupData = (signupDetails: SignupDetailsType) => {
  var validationState = {...initailSignupDetails};
  const requiredField = 'This field is required';
  if (signupDetails.fullName.trim() === '') {
    validationState.fullName = requiredField;
  }
  if (signupDetails.email.trim() === '') {
    validationState.email = requiredField;
  } else if (!CONSTANT.EMAIL_REGEX.test(signupDetails.email)) {
    validationState.email = 'Please input a valid email';
  }
  if (signupDetails.businessName.trim() === '') {
    validationState.businessName = requiredField;
  }
  if (signupDetails.businessNumber.trim() === '') {
    validationState.businessNumber = requiredField;
  }
  return validationState;
};

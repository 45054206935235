export const clearWarning = <T>(
  obj: {
    [key: string]: any;
  },
): T => {
  let newObj = {};
  for (const key in obj) {
    if (typeof obj[key as string] === 'string') {
      newObj[key] = '';
    } else if (Array.isArray(obj[key])) {
      newObj[key] = obj[key].map((item: { [key: string]: any }) =>
        clearWarning(item),
      );
    }
  }
  return newObj as T;
};

// if any of the entries is true we know that an error is triggered
export const isAllowedtoSubmit = <T>(
  validation: T
) => {
    return !Object.entries(validation).some(([_, value]) => value);
};

import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from '../../utils/hooks';
import {useHistory, useLocation} from 'react-router-dom';
import {Header2} from '../../presentation/word';
import './styles.css';
import Header from '../../presentation/header';
import CONSTANT from '../../config/constant';
import COLOR from '../../styled/colors';
import styled from 'styled-components';
import {Button, makeStyles, Grid} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {ClassNameMap} from '@material-ui/styles';
import MyButton from '../../presentation/button';
import {callAPI} from '../../utils/network';
import API from '../../config/api';
import {POST_BUSINESS_ID, POST_BUSINESS_PROFILE_DATA} from '../../store/profile/types';


export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${COLOR.WHITE};
  background-size: 1920px 1080px;
  display: flex;
  justify-content: center;
`;
export const Line = styled.hr`
  width: 100%;
  color: ${COLOR.GREEN_BUTTON};
  background-color: ${COLOR.GREEN_BUTTON};
  border-color: ${COLOR.GREEN_BUTTON};
  height: 3px;
  border-width: 0px;
`;

export const ContentBox = styled.div`
  height: 100%;
  width: 100%;
  max-width: 800px;
  max-height: 748px;
  margin-top: 40px;
  background-color: ${COLOR.WHITE};
  display: flex;
`;

const IndustryTypeTab = styled.div`
  cursor: pointer;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const SubIndustryTab = styled.div`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  justify-self: center;
  display: flex;
  background-color: ${COLOR.WHITE};
  border-radius: 10px;
`;

const StyledGrid = styled.div`
  cursor: pointer;
  margin-top: 25px;
  display: grid;
  width: 685px;
  max-height: 220px;
  margin-left: 45px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 18px;
  border-radius: 3px;
  background-color: ${COLOR.GRAY_SOLID};
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
`;

const Header3 = styled.h3`
  margin: 0;
  font-size: 25px;
  font-family: 'Oswald';
  line-height: 37px;
  font-weight: 400;
`;

const useStyles = makeStyles((theme) => ({
  greenText: {
    color: COLOR.GREENT_TEXT,
    fontSize: 16,
    textTransform: 'none',
  },
  header2: {
    marginTop: 30,
  },
  line2: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  industryTab: {
    height: 126,
    width: 200,
    backgroundColor: COLOR.WHITE,
    border: `solid 1px ${COLOR.GRAY_BORDER}`,
  },
  industryTabSelected: {
    height: 120,
    width: 194,
    backgroundColor: COLOR.GRAY_SOLID,
    border: `solid 3px ${COLOR.BLACK_BG}`,
  },
  subIndustryTab: {
    height: 48,
    width: 143,
    backgroundColor: COLOR.WHITE,
  },
  subIndustryTabSelected: {
    height: 42,
    width: 137,
    backgroundColor: COLOR.WHITE,
    border: `solid 3px ${COLOR.GREEN_BUTTON}`,
    color: COLOR.GREEN_BUTTON,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  container: {
    height: 200,
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    padding: 45,
  },
}));

type Props = {};
type IndustryTypeBlockProps = {
  selectedIndustryType: string;
  setSelectedIndustryType: (value: any) => void;
  classes: ClassNameMap<any>;
  breakIndex: number;
  firstLine: boolean;
  selectedSubIndustry: string;
  setSelectedSubIndustry: (value: any) => void;
};
type businessProfileTypes = {
  companyName: string;
  companyNumber: string | number;
};
type IndustryObjectType = {
  industryType: string;
  subIndustryType: string;
};
type NewTokenOffChain = {
  type: string;
  tokenId: string;
  generateAgliveToken: boolean;
  uniqueExternalIds?: Array<string>;
  externalIds: {[key: string]: string};
  details: {[key: string]: string | Array<any> | number};
};

const IndustryTypeBlock: React.FC<IndustryTypeBlockProps> = ({
  selectedIndustryType,
  setSelectedIndustryType,
  classes,
  breakIndex,
  firstLine,
  selectedSubIndustry,
  setSelectedSubIndustry,
}) => {
  return (
    <>
      <Grid container item className={classes.container}>
        {Object.keys(CONSTANT.INDUSTRY_TYPE).map((businessKey, index) => {
          const flag = firstLine ? index < breakIndex : index >= breakIndex;
          if (flag) {
            return (
              <IndustryTypeTab
                className={
                  selectedIndustryType ===
                  CONSTANT.INDUSTRY_TYPE[businessKey].KEYNAME
                    ? classes.industryTabSelected
                    : classes.industryTab
                }
                onClick={() =>
                  setSelectedIndustryType(
                    CONSTANT.INDUSTRY_TYPE[businessKey].KEYNAME,
                  )
                }>
                <Header3 invert={true}>
                  {CONSTANT.INDUSTRY_TYPE[businessKey].TITLE}
                </Header3>
              </IndustryTypeTab>
            );
          } else {
            return null;
          }
        })}
      </Grid>
      {CONSTANT.INDUSTRY_TYPE[selectedIndustryType]?.SUBTYPE?.length > 0 &&
        (firstLine
          ? Object.keys(CONSTANT.INDUSTRY_TYPE).indexOf(selectedIndustryType) <
            breakIndex
          : Object.keys(CONSTANT.INDUSTRY_TYPE).indexOf(selectedIndustryType) >=
            breakIndex) && (
          <StyledGrid>
            {(CONSTANT.INDUSTRY_TYPE[selectedIndustryType].SUBTYPE ?? []).map(
              (subIndustry) => (
                <SubIndustryTab
                  className={
                    subIndustry.TITLE === selectedSubIndustry
                      ? classes.subIndustryTabSelected
                      : classes.subIndustryTab
                  }
                  onClick={() => setSelectedSubIndustry(subIndustry.TITLE)}>
                  <Header3 invert={true}>{subIndustry.TITLE}</Header3>
                </SubIndustryTab>
              ),
            )}
          </StyledGrid>
        )}
    </>
  );
};

const SelectIndustryType: React.FC<Props> = (_) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [selectedIndustryType, setSelectedIndustryType] = useState(null);
  const [selectedSubIndustry, setSelectedSubIndustry] = useState(null);
  const location = useLocation<{
    businessName: string;
    businessNumber: string;
  }>();
  const {businessName, businessNumber} = location.state;

  // only show Next button when industry type or subtype is selected
  const showButton = (): boolean => {
    const noSubIndustry = [];
    for (const [key, value] of Object.entries(CONSTANT.INDUSTRY_TYPE)) {
      if (!value.hasOwnProperty('SUBTYPE')) {
        noSubIndustry.push(key);
      }
    }
    if (noSubIndustry.includes(selectedIndustryType)) {
      return true;
    } else {
      if (selectedSubIndustry) return true;
    }
    return false;
  };

  const createBusiness = useCallback(
    (
      businessProfileData: businessProfileTypes,
      industryData: IndustryObjectType,
      isAngusBusiness: boolean,
    ) => {
      return async (dispatch: (arg0: any) => void) => {
        try {
          if (!isAngusBusiness) {
            const businessData = Object.assign(
              businessProfileData,
              industryData,
            );
            const tokens: Array<NewTokenOffChain> = [
              {
                type: CONSTANT.ASSETTYPE.BUSINESS,
                tokenId: '',
                externalIds: {},
                generateAgliveToken: true,
                details: businessData,
              },
            ];

            const response = await callAPI({
              url: API.POST.createToken,
              method: 'POST',
              data: {
                tokens,
              },
            });
            
            dispatch({
              type: POST_BUSINESS_ID,
              payload: response?.data[0]?.externalIds[0]?.agliveToken,
            });

            dispatch({
              type: POST_BUSINESS_PROFILE_DATA,
              payload: businessProfileData,
            });
            history.push({
              pathname: '/private/',
              state: {fromAuth: true, newSsoUser: true},
            });

            return response;
          }
        } catch (e) {
          throw e;
        }
      };
    },
    [history],
  );

  const handleButtonClick = useCallback(() => {
    dispatch(
      createBusiness(
        {companyName: businessName, companyNumber: businessNumber},
        {
          industryType: selectedIndustryType,
          subIndustryType: selectedSubIndustry,
        },
        false,
      ),
    );
  }, [
    businessName,
    businessNumber,
    selectedIndustryType,
    selectedSubIndustry,
    dispatch,
    createBusiness,
  ]);

  useEffect(() => {
    setSelectedSubIndustry(null);
  }, [selectedIndustryType]);

  return (
    <div>
      <Header />
      <Line />
      <Container>
        <ContentBox style={{flexDirection: 'column'}}>
          <Grid
            container
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <ArrowBackIosIcon
              style={{
                width: 14,
                height: 18,
                margin: '12px 0px 0px 12px',
              }}
            />
            <Button onClick={history.goBack}>
              <span className={classes.greenText}>
                <u>{'Back'}</u>
              </span>
            </Button>
          </Grid>
          <Grid container item style={{justifyContent: 'center'}}>
            <Header2 invert={true} className={classes.header2}>
              Select Industry Type
            </Header2>
            <hr className={classes.line2} />
          </Grid>
          <IndustryTypeBlock
            selectedIndustryType={selectedIndustryType}
            setSelectedIndustryType={setSelectedIndustryType}
            selectedSubIndustry={selectedSubIndustry}
            setSelectedSubIndustry={setSelectedSubIndustry}
            classes={classes}
            breakIndex={3}
            firstLine={true}
          />
          <IndustryTypeBlock
            selectedIndustryType={selectedIndustryType}
            setSelectedIndustryType={setSelectedIndustryType}
            selectedSubIndustry={selectedSubIndustry}
            setSelectedSubIndustry={setSelectedSubIndustry}
            classes={classes}
            breakIndex={3}
            firstLine={false}
          />
          {showButton() && (
            <Grid item className={classes.buttonContainer}>
              <MyButton
                text={'Next'}
                variant="contained"
                width={296}
                height={64}
                fontSize={22}
                onClick={handleButtonClick}
              />
            </Grid>
          )}
        </ContentBox>
      </Container>
    </div>
  );
};

export default SelectIndustryType;

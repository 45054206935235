import React, {useState, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {useParams, Redirect, useHistory} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MyButton from '../../presentation/button';
import {PageHeader} from '../../presentation/withHeader';
import {postConsignee, editConsignee} from '../../store/add/actions';
import {Grid, Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {ConsigneeTypes} from '../../store/add/types';
import CONSTANT from '../../config/constant';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {initailConsignee} from '../../store/add/reducers';
import {BusinessMisc} from '@aglive/data-model';
import {FormHelperText} from '@material-ui/core';
import {MobileInput} from './AddEditUser';
import { clearWarning, isAllowedtoSubmit } from '../../utils/validation';

const AddConsginee: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {id} = useParams<{id: string}>();
  const COUNTRY_LIST = Object.keys(BusinessMisc.SupportedCountries);
  const STATE_OPTIONS = BusinessMisc.SupportedCountries;
  const consigneeList: Array<ConsigneeTypes> = useAppSelector(
    (state) => state.add.consigneeData,
  );
  const targetIndex = id
    ? consigneeList.findIndex((consignee) => consignee.agliveToken === id)
    : '';
  const [consignee, setConsignee] = useState(
    id ? {...consigneeList[targetIndex]} : initailConsignee,
  );
  const [consigneeValidation, setConsigneeValidation] = useState({
    ...initailConsignee,
  });
  const handleChange = (key: keyof typeof consignee) => (e) => {
    if (key === 'receiverCountry') {
      setConsignee((preState) => ({...preState, receiverState: ''}));
      setConsignee((preState) => ({...preState, [key]: e.target.value}));
    } else if (key === 'receiverPhone') {
      setConsignee((preState) => ({...preState, [key]: e}));
    } else {
      setConsignee((preState) => ({...preState, [key]: e.target.value}));
    }
  };
  const saveButton = () => {
    const validatedData = validateConsigneeData(consignee);
    setConsigneeValidation(validatedData);
    const isAllowed = isAllowedtoSubmit( validatedData);
    if (isAllowed) {
      if (consignee.createdAt) {
        dispatch(
          editConsignee(consignee, () => {
            history.push('/private/add/consignees');
          }),
        );
      } else {
        dispatch(
          postConsignee(consignee, () => {
            history.goBack();
          }),
        );
      }
    }
  };
  // reset validationState and warnings onKeyDown
  useEffect(() => {
    const resetValidationState = () =>
      setConsigneeValidation((prevState) => clearWarning(prevState));
    window.addEventListener('keydown', resetValidationState);

    return () => {
      window.removeEventListener('keydown', resetValidationState);
    };
  }, []);
  return (
    <PageHeader
      config={{
        title: consignee.createdAt ? 'Edit Consignee' : 'Add Consignee',
        margin: 60,
        back: true,
      }}>
      <Grid container spacing={3}>
        <Grid item container direction="column" xs={6}>
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Consignee Name'}
          </Typography>
          <TextField
            variant="outlined"
            value={consignee.receiverName}
            onChange={handleChange('receiverName')}
            helperText={consigneeValidation.receiverName}
            error={!!consigneeValidation.receiverName}
          />
        </Grid>
        <Grid item container direction="column" xs={6}>
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Consignee Email'}
          </Typography>
          <TextField
            variant="outlined"
            value={consignee.receiverEmail}
            onChange={handleChange('receiverEmail')}
            helperText={consigneeValidation.receiverEmail}
            error={!!consigneeValidation.receiverEmail}
          />
        </Grid>
        <Grid item container direction="column" xs={6}>
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Consignee Phone'}
          </Typography>
          <PhoneInput
            international
            defaultCountry="AU"
            countryCallingCodeEditable={false}
            inputComponent={MobileInput}
            value={consignee.receiverPhone}
            countries={['AU', 'CA', 'AR']}
            onChange={handleChange('receiverPhone')}
          />
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Country'}
          </Typography>
          <FormControl
            variant="outlined"
            error={!!consigneeValidation.receiverCountry}>
            <InputLabel id={'consigneeCountry'}>{'Please Select'}</InputLabel>
            <Select
              value={consignee.receiverCountry}
              labelId={'constru-select'}
              label={'Please Select'}
              onChange={handleChange('receiverCountry')}>
              {COUNTRY_LIST.map((cty) => (
                <MenuItem value={cty} key={cty}>
                  {cty}
                </MenuItem>
              ))}
            </Select>
            {!!consigneeValidation.receiverCountry && (
              <FormHelperText>
                {consigneeValidation.receiverCountry}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Post Code'}
          </Typography>
          <TextField
            variant="outlined"
            value={consignee.receiverPostCode}
            onChange={handleChange('receiverPostCode')}
            error={!!consigneeValidation.receiverPostCode}
            helperText={consigneeValidation.receiverPostCode}
          />
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Town'}
          </Typography>
          <TextField
            variant="outlined"
            value={consignee.receiverTown}
            onChange={handleChange('receiverTown')}
            error={!!consigneeValidation.receiverTown}
            helperText={consigneeValidation.receiverTown}
          />
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'State/Province/Territory'}
          </Typography>
          <FormControl
            variant="outlined"
            error={!!consigneeValidation.receiverState}>
            <InputLabel id={'receiverState'}>{'Please Select'}</InputLabel>
            <Select
              labelId={'receiverStateSelect'}
              label={'Please Select'}
              value={consignee.receiverState}
              onChange={handleChange('receiverState')}>
              {STATE_OPTIONS[consignee.receiverCountry ?? 'Australia']?.map(
                (state) => (
                  <MenuItem value={state} key={state}>
                    {state}
                  </MenuItem>
                ),
              )}
            </Select>
            {!!consigneeValidation.receiverState && (
              <FormHelperText>
                {consigneeValidation.receiverState}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Consignee Location ID (if applicable)'}
          </Typography>
          <TextField
            variant="outlined"
            value={consignee.receiverPIC}
            onChange={handleChange('receiverPIC')}
            error={!!consigneeValidation.receiverPIC}
            helperText={consigneeValidation.receiverPIC}
          />
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Consignee Address'}
          </Typography>
          <TextField
            variant="outlined"
            value={consignee.receiverAddress}
            onChange={handleChange('receiverAddress')}
            error={!!consigneeValidation.receiverAddress}
            helperText={consigneeValidation.receiverAddress}
            multiline={true}
            minRows={3}
          />
        </Grid>
        <Grid item container justify="flex-end" style={{marginTop: 300}}>
          <MyButton text={'Save'} variant="contained" onClick={saveButton} />
        </Grid>
      </Grid>
    </PageHeader>
  );
};
export default AddConsginee;

const validateConsigneeData = (consignee: ConsigneeTypes) => {
  var validationState = {...initailConsignee};
  const requiredField = 'This field is required';
  if (consignee.receiverName.trim() === '') {
    validationState.receiverName = requiredField;
  }
  if (consignee.receiverEmail.trim() === '') {
    validationState.receiverEmail = requiredField;
  } else if (!CONSTANT.EMAIL_REGEX.test(consignee.receiverEmail)) {
    validationState.receiverEmail = 'Please input a valid email';
  }
  if (consignee.receiverPhone.trim() === '') {
    validationState.receiverPhone = requiredField;
  } else if (!isPossiblePhoneNumber(consignee.receiverPhone ?? '')) {
    validationState.receiverPhone = 'Please input a valid phone number';
  }
  if (consignee.receiverCountry?.trim() === '') {
    validationState.receiverCountry = requiredField;
  }
  if (consignee.receiverState?.trim() === '') {
    validationState.receiverState = requiredField;
  }
  if (!consignee.receiverPostCode.trim()) {
    validationState.receiverPostCode = requiredField;
  } else if (
    !CONSTANT.POSTCODE_REGEX[consignee.receiverCountry as string]?.test(
      consignee.receiverPostCode,
    )
  ) {
    validationState.receiverPostCode =
      CONSTANT.POSTCODE_REGEX_WARNING[consignee.receiverCountry as string];
  }
  if (consignee.receiverTown?.trim() === '') {
    validationState.receiverTown = requiredField;
  }
  if (consignee.receiverAddress?.trim() === '') {
    validationState.receiverAddress = requiredField;
  }
  return validationState;
};
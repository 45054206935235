import React, {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  useImperativeHandle,
  useState,
} from 'react';

import {HyperLink} from '../../presentation/word';
import {TextField} from '@material-ui/core';
import {HelpOutlined} from '@material-ui/icons';
import CONSTANT from '../../config/constant';
import {InvitationCodeModalProps, InvitationCodeRefObj} from './SsoLogin';

const InvitationCodeModal: ForwardRefRenderFunction<
  InvitationCodeRefObj,
  PropsWithChildren<InvitationCodeModalProps>
> = (props, ref) => {
  const [code, setCode] = useState('');

  useImperativeHandle(ref, () => ({
    getInvitationCode: () => {
      return code;
    },
  }));

  return (
    <>
      <TextField
        key={`invitationCode`}
        variant="outlined"
        margin="dense"
        value={code}
        onChange={(e) => {
          setCode(e.target.value);
        }}
        className={props.classes.invitationCode}
      />
      <div style={{display: 'flex', marginTop: 24}}>
        <HelpOutlined style={{height: 24, width: 24}} />
        <HyperLink
          style={{marginLeft: 10}}
          target="_blank"
          href={CONSTANT.GET_INVITATION_CODE_URL}>
          How do I get the code?
        </HyperLink>
      </div>
    </>
  );
};

export default forwardRef(InvitationCodeModal);

import React, {useEffect} from 'react';
import {useAppDispatch} from '../../utils/hooks';
import {useHistory, useLocation} from 'react-router-dom';
import {ssoAuthUser} from '../../store/auth/actions';
import agliveLogoLarge from '../../img/agliveLogoLarge.png';
import googleLogo from '../../img/g-logo.png';
import qrLogo from '../../img/qr-logo.png';
import {tokenService} from '../../env';
import Button from '../../presentation/button';
import {
  ContainerFoot,
  Line,
  Logo,
  SsoContainer,
  SsoLeftSection,
  SsoLoginBox,
  SsoRightSection,
} from './styles';
import {Header2} from '../../presentation/word';
import './styles.css';
import Avatar from '@mui/material/Avatar';
import CONSTANT from '../../config/constant';

type Props = {};

const Auth: React.FC<Props> = (_) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const login = useLocation().search;

  useEffect(() => {
    // get authorizationCode from rederect url and auth user
    const urlParams = new URLSearchParams(login);
    if (urlParams.has('authorizationCode')) {
      const authorizationCode = urlParams.get('authorizationCode');
      dispatch(ssoAuthUser(authorizationCode, history));
    }
  }, [login, dispatch, history]);

  const handleGoogleSSO = async () => {
    const googleLoginURL = tokenService + CONSTANT.SSO_URL;
    window.open(googleLoginURL, '_self');
  };

  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <SsoContainer>
          <SsoLoginBox>
            <SsoLeftSection>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '-60px 95px 0px 45px',
                  padding: 20,
                }}>
                <Logo src={agliveLogoLarge} style={{marginLeft: 40}} />
                <Header2 invert={true} style={{marginTop: 30}}>
                  Prove the value of your product and boost sales{' '}
                </Header2>
              </div>
              <ContainerFoot>
                Copyright &reg; 2020 Aglive International Pty Ltd ACN 605 120
                081
              </ContainerFoot>
            </SsoLeftSection>
            <SsoRightSection>
              <Header2 invert={true}>Get started in seconds</Header2>
              <Line mt={25} style={{width: '80%'}} />
              <Button
                variant={'outlined'}
                text={'Sign In With Google'}
                style={{
                  margin: '40px 0px 10px 0px',
                  color: 'black',
                  'font-size': '25px',
                }}
                startIcon={<Avatar src={googleLogo} />}
                onClick={handleGoogleSSO}
              />
              <Button
                variant={'outlined'}
                text={'Sign In With QR Scan'}
                style={{
                  margin: '30px 0px 10px 0px',
                  color: 'black',
                  'font-size': '25px',
                }}
                onClick={() => {
                  history.push({
                    pathname: '/qr-login',
                    state: {fromAuth: true},
                  });
                }}
                startIcon={<Avatar src={qrLogo} />}
              />
            </SsoRightSection>
          </SsoLoginBox>
        </SsoContainer>
      </div>
    </div>
  );
};

export default Auth;
